import React, { useCallback, useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';

import { Measurement } from 'common/types/mix';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import TextField from 'common/ui/filaments/TextField';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  onChange: (value?: Measurement) => void;
  // defaultUnit should be one of validUnits
  defaultUnit: string;
  validUnits: string[];
} & ParameterEditorBaseProps<Measurement>;

const MeasurementEditor = React.memo(function MeasurementEditor(props: Props) {
  const { onChange, value: measurement, validUnits, defaultUnit } = props;

  // error only for non-number values since the editor is for string inputs and
  // we must do the conversion to a number. We disable clearing units, so the
  // only way a unit can be empty or invalid is if the parent component
  // intentionally sets it. All other errors should be dealt with by the parent
  // since they are context specific (e.g. not allowing negative values etc)
  const [error, setError] = useState('');
  const [value, setValue] = useState(measurement ? measurement.value.toString() : '');
  const [unit, setUnit] = useState(measurement ? measurement.unit : defaultUnit);
  const [displayUnit, setDisplayUnit] = useState(unit);

  const handleUnitChange = useCallback(
    (_event: React.SyntheticEvent, newUnit: string) => {
      // should always be a valid unit since we disable clearable
      setUnit(newUnit);
      onChange({ value: +value, unit: newUnit });
    },
    [onChange, value],
  );

  const handleDisplayUnitChange = useCallback(
    (_event: React.SyntheticEvent, unit: string) => setDisplayUnit(unit),
    [],
  );

  const handleValueChange = useTextFieldChange((newValue: string) => {
    setValue(newValue);
    if (!newValue) {
      setError(''); // undefined value is not an error
      onChange(undefined);
      return;
    }
    const num = +newValue;
    if (isNaN(num)) {
      setError('Only numbers are valid');
      onChange(undefined);
    } else {
      setError('');
      onChange({ value: num, unit });
    }
  });

  useEffect(() => {
    // 1. don't disrupt the user entering floating point values
    const float = value.split('.')[1];
    const isPartiallyEntered = float !== undefined && +float === 0;
    // 2. persist the error value until the user corrects it. Note: 2 and string
    //    parsing has a useful side effect of converting scientific notation to
    //    normal floats (e.g. 1e-1 becomes 0.1), which users generally prefer
    if (!isPartiallyEntered && !error) {
      setValue(measurement?.value.toString() || '');
    }
    // 3. try to preserve set unit before going back to default
    setUnit(measurement ? measurement.unit : defaultUnit);
  }, [defaultUnit, error, measurement, measurement?.unit, measurement?.value, value]);

  const isSingleUnitOption = validUnits.length === 1 && !!defaultUnit;

  return (
    <StyledDiv>
      <StyledTextField
        placeholder={props.placeholder ?? ''}
        InputLabelProps={{ shrink: true }}
        value={value}
        onChange={handleValueChange}
        disabled={props.isDisabled}
        required={props.isRequired}
        error={error !== '' || props.hasError}
        helperText={error}
        inputProps={{ style: { textAlign: 'right' } }}
      />
      <StyledAutocomplete
        blurOnSelect
        disableClearable
        multiple={false}
        disabled={props.isDisabled || !value}
        readOnly={isSingleUnitOption}
        forcePopupIcon={!isSingleUnitOption}
        selectOnFocus={!isSingleUnitOption}
        options={validUnits}
        value={unit}
        onChange={handleUnitChange}
        inputValue={displayUnit}
        onInputChange={handleDisplayUnitChange}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </StyledDiv>
  );
});

const StyledDiv = styled('div')({ display: 'flex' });
const StyledAutocomplete = styled(Autocomplete<string, false, true>)({ flex: 1 });
const StyledTextField = styled(TextField)({ flex: 1.25, 'margin-right': '10px' });

export default MeasurementEditor;

/*
 * When adding a new screen, you need to:
 * 1. Add a new entry to the ScreenRegistry below
 * 2. Update the mainNav.ts with what should be shown.
 */

export const ScreenRegistry = {
  CHERRY_PICKER: 'cherry-picker',
  FILE_BROWSER: 'file-browser',
  DEVICE_LIBRARY: 'devices',
  SIMULATION_DETAILS: 'simulation-details',
  EXECUTION_DETAILS: 'execution-details',
  EXPERIMENTS: 'experiments',
  EXPERIMENT_DETAIL: 'experiment',
  TEMPLATE_WORKFLOWS: 'template-workflow',
  FORM: 'form',
  PROTOCOL: 'protocol',
  PROTOCOL_INSTANCE: 'protocol-instance',
  PLATE_TYPE_LIBRARY: 'plate-types',
  POLICY_LIBRARY: 'liquid-policies',
  WORKFLOW: 'workflow',
  PROFILE: 'profile',
  DOE_TEMPLATE: 'doe-template',
  DOE_DESIGN: 'doe-design',
  VISUALIZATION: 'visualization',
  VIS_VIEW: 'vis-view',
  FEATURE_TOGGLE: 'feature-toggles',
  LOCAL_DEV: 'local-mix-preview', // used to be local-dev
  MARKDOWN_DEV: 'markdown-dev',
  RESPONSE_ANALYSIS: 'response-analysis',
  WORK_TREE: 'work-tree',
  NOT_FOUND_404: '404',
  HOME: '', // e.g. antha.ninja/#/ - Redirects to Experiments pag,
} as const;

/**
 * Screens that will just be redirected, and therefore do not require screen information
 * set up in mainNav.ts.
 */
export const LegacyScreenRegistry = {
  LEGACY_DEVICE_LIBRARY: 'antha-device-library',
  LEGACY_EXECUTION_DETAILS: 'antha-jobs/execution-details',
  LEGACY_TEMPLATE_WORKFLOWS: 'pinned-workflows',
  LEGACY_PLATE_TYPE_LIBRARY: 'antha-plate-type-library',
  LEGACY_POLICY_LIBRARY: 'antha-policy-library',
  LEGACY_SIMULATIONS: 'antha-simulation-queue',
};

// Not a screen but used for tracking purposes
export const TOP_NAV_MENU_ID = 'top-nav-menu';

export type AnthaPlatformScreenID = (typeof ScreenRegistry)[keyof typeof ScreenRegistry];

import { useCallback, useState } from 'react';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { LiquidPolicy } from 'client/app/gql';
import { DialogProps } from 'common/ui/hooks/useDialog';

export enum Views {
  LIST,
  DETAILS,
  XLSX_CONTENT,
}

type Props = {
  value: string;
} & DialogProps<string | undefined | typeof CANCEL_CHOICE>;

export default function usePolicyHandlers({
  value,
  onClose,
}: Pick<Props, 'value' | 'onClose'>) {
  const [policyParameterValue, setPolicyParameterValue] = useState<string | undefined>(
    value,
  );

  // Policy handlers
  const [selectedPolicy, setSelectedPolicy] = useState<LiquidPolicy | undefined>();
  const togglePolicy = useCallback(
    (policy: LiquidPolicy) => {
      if (policy.name === selectedPolicy?.name) {
        setSelectedPolicy(undefined);
        setPolicyParameterValue(undefined);
      } else {
        setSelectedPolicy(policy);
        setPolicyParameterValue(policy.name);
      }
    },
    [selectedPolicy?.name],
  );
  const selectPolicyAndCloseDialog = useCallback(
    (policy: LiquidPolicy) => {
      setSelectedPolicy(policy);
      setPolicyParameterValue(policy.name);
      onClose(policy.name);
    },
    [onClose],
  );

  // View handlers
  const [activeView, setActiveView] = useState(Views.LIST);
  const showList = useCallback(() => {
    setActiveView(Views.LIST);
  }, []);
  const showDetails = useCallback((policy: LiquidPolicy) => {
    setSelectedPolicy(policy);
    setPolicyParameterValue(policy.name);
    setActiveView(Views.DETAILS);
  }, []);
  /**
   * FIXME: XLSX view is added for backward compatibility with XLSX file policies
   * the related methods should be removed when it is deprecated.
   */
  const showXlsxContentView = useCallback(() => {
    setActiveView(Views.XLSX_CONTENT);
  }, []);
  const selectXlsxPolicy = (selectedLiquidType: string | undefined) => {
    setPolicyParameterValue(selectedLiquidType);
  };
  const selectXlsxPolicyAndCloseDialog = (selectedLiquidType: string | undefined) => {
    setPolicyParameterValue(selectedLiquidType);
    onClose(selectedLiquidType);
  };

  // Dialog handlers
  const submitDialog = useCallback(() => {
    onClose(policyParameterValue);
  }, [onClose, policyParameterValue]);

  const goBack = activeView !== Views.LIST ? showList : undefined;

  const cancelDialog = useCallback(() => {
    setSelectedPolicy(undefined);
    setPolicyParameterValue(value);
    onClose(CANCEL_CHOICE);
  }, [onClose, value]);

  return {
    policyParameterValue,
    policy: selectedPolicy,
    view: {
      active: activeView,
      showList,
      showDetails,
      togglePolicy,
      selectPolicyAndCloseDialog,
      /**
       * FIXME: XLSX view is added for backward compatibility with XLSX file policies
       * the related methods should be removed when it is deprecated.
       */
      showXlsxContentView,
      toggleXlsxPolicy: selectXlsxPolicy,
      selectXlsxPolicyAndCloseDialog,
    },
    dialog: {
      submit: submitDialog,
      cancel: cancelDialog,
      goBack,
    },
  };
}

import memoize from 'lodash/memoize';

import { assert } from 'common/lib/assertions';
import {
  Measurement,
  MeasurementWithStdDev,
  PipettingHeight,
  VolumeConsequence,
  WellLocation,
  WellLocationOnDeckItem,
} from 'common/types/mix';

/**
 * Returns a work in singular or plural form depending on given number. This includes the count, e.g.:
 * - `pluralize(0, 'hour')` -> '0 hours'
 * - `pluralize(1, 'hour')` -> '1 hour'
 * - `pluralize(2, 'hour')` -> '2 hours'
 * - `pluralize(0, 'box', 'boxes')` -> '0 boxes'
 * - `pluralize(1, 'box', 'boxes')` -> '1 box'
 */
export function pluralize(n: number, what: string, plural?: string) {
  return `${n} ${pluralizeWord(n, what, plural)}`;
}

/**
 * Returns a work in singular or plural form depending on given number. This does not include the count, e.g.:
 * - `pluralizeWord(0, 'hour')` -> 'hours'
 * - `pluralizeWord(1, 'hour')` -> 'hour'
 * - `pluralizeWord(2, 'hour')` -> 'hours'
 * - `pluralizeWord(0, 'box', 'boxes')` -> 'boxes'
 * - `pluralizeWord(1, 'box', 'boxes')` -> 'box'
 */
export function pluralizeWord(n: number, what: string, plural: string = what + 's') {
  return n === 1 ? what : plural;
}

export function ellipsize(s: string, maxLen: number): string {
  if (!s) {
    return '';
  }
  if (s.length <= maxLen) {
    return s;
  }
  return s.substring(0, maxLen) + '...';
}

export function formatDateTime(dateTime: Date, separator?: string) {
  return (
    formatDate(dateTime) + (separator ? ` ${separator} ` : ' ') + formatTime(dateTime)
  );
}

export function formatDate(dateTime: Date) {
  // undefined means "current locale"
  return dateTime.toLocaleDateString(undefined, {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  });
}

export function formatTime(dateTime: Date) {
  // show only hours and minutes, use options with the default locale - use an empty array
  return dateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatDateTimeWithTimezone(timestamp: Date, timeZone: string) {
  // Format a given timestamp to a format like: '03 Apr 2023 15:38 (GMT +1)
  const formatter = new Intl.DateTimeFormat('en-GB', {
    month: 'short',
    year: 'numeric',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
    timeZoneName: 'shortOffset',
  });
  const ts = collectDateTimeParts(formatter.formatToParts(timestamp));
  let result = `${ts.day} ${ts.month} ${ts.year} ${ts.hour}:${ts.minute}`;
  if (ts.timeZoneName) {
    result += ` (${ts.timeZoneName})`;
  }
  return result;
}

function collectDateTimeParts(
  datetimeParts: Intl.DateTimeFormatPart[],
): Intl.DateTimeFormatPartTypesRegistry {
  const result = new Object() as Intl.DateTimeFormatPartTypesRegistry;
  for (const part of datetimeParts) {
    if (part.type !== 'literal') {
      result[part.type] = part.value;
    }
  }
  return result;
}

export function formatDuration(durationSeconds: number | null): string {
  if (!durationSeconds) {
    return '';
  }
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = String(Math.floor(durationSeconds % 60)).padStart(2, '0');
  return `${hours}h ${minutes}m ${seconds}s`;
}

export function formatFileSize(bytes: number): string {
  const suffixes = ['KB', 'MB', 'GB', 'TB', 'PB'];
  let suffix = 'bytes';
  let finalNum = bytes;
  let tempNum = bytes;
  for (let ii = 0; ii < suffixes.length; ii++) {
    tempNum = tempNum / 1024;
    if (tempNum < 1) {
      break;
    }
    finalNum = Math.round(tempNum * 100) / 100; // 2 decimal place rounding
    suffix = suffixes[ii];
  }

  return finalNum + ' ' + suffix;
}

export function toMaxPrecision(value: number, precision: number) {
  return value.toPrecision(
    Math.min(Math.abs(value).toString().replace('.', '').length, precision),
  );
}

export function getVolumeString(volInMl: number) {
  const lengthOfNumber = Math.abs(volInMl).toFixed(0).length;
  if (lengthOfNumber > 3) {
    return `${toMaxPrecision(volInMl / 1000, 4)}ml`;
  }
  return `${toMaxPrecision(volInMl, 4)}${String.fromCharCode(181)}l`;
}

// Plus-minus sign surrounded by non-breaking space on each side.
// We don't want the value and its associated error to be broken up into two lines.
const PLUS_MINUS = '\u00A0\u00B1\u00A0';

export function formatMeasurementObj(
  measurement: Measurement,
  useNonBreakingSpace: boolean = true,
) {
  return formatMeasurement(measurement.value, measurement.unit, useNonBreakingSpace);
}

/**
 * For R&D project tracking precision uncertainties through liquid-handling
 * workflows
 */
export function formatVolumeWithStdDev(
  volume: MeasurementWithStdDev,
  useNonBreakingSpace: boolean = true,
) {
  if (volume.stddev) {
    const space = useNonBreakingSpace ? '\u00A0' : ' ';
    return `${volume.value}${PLUS_MINUS}${volume.stddev}${space}${volume.unit}`;
  } else {
    // Format the standard way
    return formatMeasurementObj(volume, useNonBreakingSpace);
  }
}

/**
 * useNonBreakingSpace option should be used for display purposes only, not
 * generating parameter values.
 */
export function formatMeasurement(
  value: number,
  unit: string,
  useNonBreakingSpace: boolean = true,
) {
  // \u00A0 is a non-breaking space. We never want the number and unit to be
  // broken up into different lines.
  // See https://shripadk.github.io/react/docs/jsx-gotchas.html
  const space = useNonBreakingSpace ? '\u00A0' : ' ';
  return `${roundNumber(value)}${space}${unit}`;
}

export function formatVolumeConsequence(value: VolumeConsequence): string {
  if (value.kind === 'constant') {
    return formatMeasurementObj(value.constant_volume);
  } else if (value.kind === 'maximum') {
    return `Max(${value.volumes.map(formatVolumeConsequence).join(', ')})`;
  } else if (value.kind === 'minimum') {
    return `Min(${value.volumes.map(formatVolumeConsequence).join(', ')})`;
  } else if (value.kind === 'scaled') {
    return `${roundNumber(value.factor)} * ${formatVolumeConsequence(
      value.volume_to_scale,
    )}`;
  } else if (value.kind === 'property') {
    return value.property;
  } else {
    return 'unknown';
  }
}

/**
 * Convert string of form '1uL' to `{ value: 1, unit: 'uL' }`.
 */
export function parseMeasurement(str: string): Measurement | undefined {
  // Divide the string at the first non-numeric character. The first capture
  // group needs to be non-greedy.
  const match = str.trim().match(/^(.+?)([^-+.0-9].*)$/);
  const value = match ? Number(match[1]) : NaN;
  if (!match || isNaN(value)) {
    // Not a valid input string
    return undefined;
  }
  return { value, unit: match[2].trim() };
}

/**
 * Check if the given value is a valid amount (e.g. 100 uL).
 */
export const validateMeasurement = (value?: string) => !!parseMeasurement(value || '');

/**
 * Checks the `expression` string to only contain logical expressions with measurements.
 *
 * Valid examples:
 * - ">= 20ul"
 * - ">= 20ul & < 50ul"
 *
 * Invalid examples:
 * - "20ul <"
 * - ">= 20ul - 50ul"
 */
export function validateMeasurementExpression(
  expression: string,
  allowedUnits: string[],
): boolean {
  const allowedUnitsPattern = allowedUnits.join('|');
  const MEASUREMENTS_LOGICAL_EXPRESSION_REGEXP = new RegExp(
    `^(?:\\s*(>=|<=|>|<|=)\\s*\\d+(\\.\\d+)?\\s*(${allowedUnitsPattern})\\s*)` +
      `(?:\\s*&\\s*(>=|<=|>|<|=)\\s*\\d+(\\.\\d+)?\\s*(${allowedUnitsPattern})\\s*)*$`,
  );
  return MEASUREMENTS_LOGICAL_EXPRESSION_REGEXP.test(expression);
}

/**
 * For a well position on a plate, return the user-friendly name, e.g. "C1". The letter is
 * the row, the number is the column. These positions are labelled this way on the
 * physical plastic plates and are important to scientists.
 */
export function formatWellPosition(row: number, col: number): string;
export function formatWellPosition(
  wellLocation: WellLocationOnDeckItem | WellLocation,
): string;
export function formatWellPosition(
  rowOrWellLocation: number | WellLocationOnDeckItem | WellLocation,
  col?: number,
): string {
  if (typeof rowOrWellLocation === 'number') {
    assert(typeof col === 'number', 'Expected col to be a number');
    return `${formatWellRow(rowOrWellLocation)}${formatWellColumn(col)}`;
  } else {
    return formatWellPosition(rowOrWellLocation.row, rowOrWellLocation.col);
  }
}

// On labware, row 0 is labelled as 'A', row 1 is 'B' etc.
const ASCII_CODE_LETTER_A = 65;
const ASCII_CODE_LETTER_Z = 90;
export function formatWellRow(row: number) {
  const asciiCodeForRow = row + ASCII_CODE_LETTER_A;
  // If we reach row 'Z', next one should be 'AA', then 'AB', etc.
  // This is safe to assume as the biggest plates have 3150 wells,
  // which is 45*70. Bottom right well would be 'AS70'.
  if (asciiCodeForRow > ASCII_CODE_LETTER_Z) {
    return (
      'A' +
      String.fromCharCode(
        ASCII_CODE_LETTER_A - 1 + (asciiCodeForRow - ASCII_CODE_LETTER_Z),
      )
    );
  }
  return String.fromCharCode(asciiCodeForRow);
}

// On labware, column 0 is labelled as as '1', etc.
export function formatWellColumn(col: number) {
  return (col + 1).toString();
}

type WellGroupWithinRow = {
  row: number;
  colStart: number;
  colEnd: number;
};

type WellGroup = {
  rowStart: number;
  rowEnd: number;
  colStart: number;
  colEnd: number;
};

/**
 * Format a list of wells such that contiguous blocks are truncated, e.g.
 * - [A1, A2, B1, B2] => "A1:B2"
 * - [A1, A2, B1, B2, D12, G10, G11] => "A1:B2, D12, G10:G11"
 *
 * This works grouping contiguous wells in each row, and then joining matching
 * groups in neighbouring rows.
 */
export function formatWellRange(wellLocations: string[]): string {
  const sorted = wellLocations
    .map(wellLocation => ({
      col: getColumnNumberFromWellPosition(wellLocation),
      row: getRowNumberFromWellPosition(wellLocation),
    }))
    // Sort ascending by column first, then row. We cannot rely on built in
    // alphabetical sort because A2 would be ordered before A10.
    .sort((a, b) => (a.row === b.row ? a.col - b.col : a.row - b.row));

  const groupsWithinColumns: WellGroupWithinRow[] = [];
  const groupsWithinColumnsByWell = new Map<string, WellGroupWithinRow>();

  for (const { col, row } of sorted) {
    const location = formatWellPosition(row, col);
    // Check if this is next to an existing group. If it is, push this well into
    // that group. Otherwise, start a new group.
    const wellLeft = formatWellPosition(row, col - 1);
    let group = groupsWithinColumnsByWell.get(wellLeft);
    if (!group) {
      group = { row, colStart: col, colEnd: col };
      groupsWithinColumns.push(group);
    } else {
      group.colEnd = col;
    }
    groupsWithinColumnsByWell.set(location, group);
  }
  // Go through each group within each column and if there is a group in the
  // previous row with the same start/end row, then join those groups.
  const groups: WellGroup[] = [];
  for (const { row, colStart, colEnd } of groupsWithinColumns) {
    const matchingGroupInPrevColumn = groups.find(
      groupInPrevColumn =>
        groupInPrevColumn.colEnd === colEnd &&
        groupInPrevColumn.colStart === colStart &&
        groupInPrevColumn.rowEnd === row - 1,
    );
    if (matchingGroupInPrevColumn) {
      matchingGroupInPrevColumn.rowEnd = row;
    } else {
      groups.push({ rowStart: row, rowEnd: row, colStart, colEnd });
    }
  }

  return groups
    .map(({ rowStart, rowEnd, colStart, colEnd }) => {
      const start = formatWellPosition(rowStart, colStart);
      const end = formatWellPosition(rowEnd, colEnd);
      return start === end ? start : `${start}:${end}`;
    })
    .join(', ');
}

/** Example input: well "A1" will return 1, well "C2" will return 3 */
export function getRowNumberFromWellPosition(wellPosition: string) {
  const wellPositionLettersOnly = wellPosition.replace(/\d+/g, '');

  // If users type only one letter, convert that letter to row number
  //  e.g. 'A1' -> 0, 'B44' -> 1, etc.
  const onlyOneLetter = wellPositionLettersOnly.length === 1;
  if (onlyOneLetter) {
    // Well starts from A, which has a charCode of 65. We subtract it so that
    // "A" becomes 0, "B" becomes 1, etc.
    return wellPosition.charCodeAt(0) - ASCII_CODE_LETTER_A;
  }

  /**
   * If users type more than a letter, there are two cases:
   * - First letter is a 'A'. This is valid. We support wells with 2
   *   letters that start with 'A'. E.g. 'AA2', 'AF9'.
   * - First letter is not 'A'. This is not valid. We simply
   *    ignore the extra letters after the first one.
   *    E.g. 'KA2' -> 'K2'; 'TLDR22' -> 'T22'
   */
  const firstOfManyLettersNotA =
    wellPositionLettersOnly.charCodeAt(0) !== ASCII_CODE_LETTER_A;
  if (firstOfManyLettersNotA) {
    return wellPosition.charCodeAt(0) - ASCII_CODE_LETTER_A;
  }

  // After well 'Z', we have well 'AA', 'AB', etc.
  const numOfSingleLetterRows = ASCII_CODE_LETTER_Z - ASCII_CODE_LETTER_A + 1;
  return (
    wellPositionLettersOnly.charCodeAt(1) - ASCII_CODE_LETTER_A + numOfSingleLetterRows
  );
}

/** Example input: well "A1" will return 0, well "H230" will return 239 */
export function getColumnNumberFromWellPosition(wellPosition: string) {
  return Number(wellPosition.replace(/\D/g, '')) - 1;
}

/**
 * Given a list of wells, return only those that exist in the given location set.
 */
export function cropWellsToExistingLocations(
  wells: string[],
  existingLocations: { rows: number; columns: number },
): string[] {
  return wells.filter(well => wellLocationExists(well, existingLocations));
}

/**
 * Check if dimensions allow the well location.
 */
export function wellLocationExists(
  well: string,
  plate: { rows: number; columns: number },
): boolean {
  const row = getRowNumberFromWellPosition(well);
  const col = getColumnNumberFromWellPosition(well);
  return row >= 0 && row < plate.rows && col >= 0 && col < plate.columns;
}

export function formatPipettingHeight(height: PipettingHeight) {
  if (height.unit) {
    return `${roundNumber(height.value)} ${height.unit} above ${height.reference}`;
  }
  return height.reference;
}

/** Format numbers to 2dp. But, if the value is between -1 and 1, then format to 3sf.
 * Never use engineering notation.
 *
 * 123456.0   -> "123456.00"
 * 12345.6    -> "12345.60"
 * 1234.56    -> "1234.56"
 * 123.456    -> "123.46"  * rounding
 * 12.3456    -> "12.35"  * rounding
 * 1.23456    -> "1.23"
 * 0.123456   -> "0.123"
 * 0.0123456  -> "0.0123"
 * 0.00123456 -> "0.00123"
 *
 * Notes:
 * 1. Trailing 0s are allowed. E.g.
 *   0.1 -> "0.100"
 * 2. Rounding will take place. E.g.
 *   0.10051 -> "0.101"
 *   1.0051  -> "1.01"
 *
 * Make sure this stays in sync with antha, formatFloat64 function in units.
 *
 * NB: despite best efforts, this is not absolutely identical to
 * formatFloat64 in antha because the rounding mode of toPrecision is
 * a bit different from the Go %f format verb. However, it is believed
 * this is unlikely to cause problems.
 */
export function roundNumber(value: number): string {
  if (value === 0) {
    return '0.00';
  }
  if (-1 < value && value < 1) {
    return value.toPrecision(3);
  }
  return value.toFixed(2);
}

/** rounds up value to the given number of significant figures, e.g.
 *
 * roundUp(123.5, 2) -> 130
 * roundUp(500.0, 2) -> 500.0
 * roundUp(500.1, 1) -> 600
 */
export function roundUp(value: number, significantFigures = 2): number {
  const val = Math.abs(value);
  if (val === 0.0) {
    return 0.0;
  }
  const f = 10 ** (significantFigures - Math.ceil(Math.log10(val)));
  return Math.sign(value) * (Math.ceil(val * f) / f);
}

const HEX = '[0-9a-f]';
const ALPHANUMERIC = '[a-zA-Z0-9]';
const OLD_JOBS_GUID_REGEX = `_${HEX}{7}-${HEX}{4}-${HEX}{4}-${HEX}{4}-${HEX}{10}$`;
const NEW_JOBS_GUID_REGEX = `_${ALPHANUMERIC}{10}-${ALPHANUMERIC}{11}-${ALPHANUMERIC}{6}$`;
// Match either old or new version of GUID
const GUID_REGEX = new RegExp(`${NEW_JOBS_GUID_REGEX}|${OLD_JOBS_GUID_REGEX}`);

// When users don't specify any name for a deck item, the backend generates
// a unique name, such as "D200 Tip Rack (PIPETMAX 8x200)_<GUID>".
// Haydn says:
// "The default name is just “Type_GUID". Not ideal, but I’m not sure I want to
// see what happens if we change it."
// We don't want to display the GUID in the UI. Thefore, we strip it.
export function sanitizeDeckItemName(name: string): string {
  return name.replace(GUID_REGEX, '');
}

/**
 *  Returns a sensible name for a copy of something with the given name with a prefix and infix.
 *  The prefix is followed by a number (copy count) and and infix.
 *  e.g: "coolWorkflow" -> "Copy of coolWorkflow", "Copy 2 of thing" -> "Copy 3 of thing"
 */
export function getCopyName(
  existingName: string,
  prefix: string = 'Copy',
  infix: string = 'of',
) {
  const infixOrNothing = infix ? infix + ' ' : '';
  const copyDetectionRegExp = new RegExp(
    `(${prefix})((?: [0-9]+ )|(?: ))(${infixOrNothing})(.+)`,
  );
  const regexResult = copyDetectionRegExp.exec(existingName);

  // It's not a copy of anything, so just prepend the prefix.
  if (regexResult === null) {
    return `${prefix} ${infixOrNothing}${existingName}`;
  }

  const [_, prefixToKeep, sectionToUpdate, , sectionToKeep] = regexResult;

  // If the prefix is not followed by a number, the next copy will be the second.
  const newNumber = sectionToUpdate.trim() ? parseInt(sectionToUpdate.trim()) + 1 : 2;

  return `${prefixToKeep} ${newNumber} ${infixOrNothing}${sectionToKeep}`;
}

/**
 *  Make strings such as element and parameter type names more human-readable, i.e.
 *  replace underscores with spaces
 * */
export function getObjectFriendlyName(name: string) {
  return name.replace(/_/g, ' ');
}

/** Returns the substring of fullPath following the last full stop in the string */
export function trimBeforeLastFullStop(fullPath: string) {
  return fullPath.substring(fullPath.lastIndexOf('.') + 1);
}

/**
 * Remove the scheme from the given uri
 * We strip everything before and `://` (included). We do not worry about multiple `://` as this should not be allowed.
 * @param uri
 */
export function removeScheme(uri: string) {
  return uri.replace(/.*:\/\//, '');
}

/**
 * Encodes "path" for use as parameter in GET requests to filetree endpoints.
 *
 * Filetree uses endpoints with the format: .../resource/{orgId}/{filetree-path}.
 * filetree-path parameter should be percent-encoded because it might contain characters
 * that are invalid in URLs.
 *
 * Unfortunately it's not clear what is the format of various "path" variables passed around - they
 * are just strings and they seem to vary:
 * - sometimes it's FiletreeLink (with scheme prefix and everything)
 * - legacy links:
 *   (https://github.com/Synthace/antha-platform/blob/55c94d72af25d9ee54ddbcf632e7367f5a847444/antha-com/appserver/src/services/external/filetree/convertToFiletree.ts#L32-L51)
 * - sometimes it's FiletreePath - just the part to the right of orgId, e.g. /job
 *   This shouldn't work because there's no orgId but it seems to be handled
 *   by the code handling "legacy links".
 *
 * In order to send the request we should percent-encode the "filetree-path" part but it's not clear which part is that
 * because it's not clear what's the format of path.
 * So we encode it all and hope for the best. It's definitely incorrect but seems to work, for now.
 * TODO - make it better.
 */
export function filetreePathOrLinkToURLParam(value: string) {
  const noScheme = value.replace(/.*:\/\//, '');
  return encodeURIComponent(noScheme);
}

export function endsWithNumber(string: string) {
  const match = string.match(/[0-9]+$/);
  return {
    number: parseInt(match?.[0] ?? '-1', 10),
    numberIndex: match?.index,
  };
}

export const capitalize = memoize((input: string): string => {
  return input[0].toUpperCase() + input.slice(1);
});

/**
 * Transforms order operator encoding into its expression.
 *
 * e.g.  "lt" => "<", "gte" => ">="
 */
export function getOrderOperatorExpression(operatorEncoding: string): string {
  const operatorMap: Record<string, string> = {
    gte: '>=',
    lte: '<=',
    gt: '>',
    lt: '<',
    eq: '=',
  };
  return operatorMap[operatorEncoding];
}

/**
 * Transforms order operator expression into its encoding.
 *
 * e.g.  "<" => "lt", ">=" => "gte"
 */
export function getOrderOperatorEncoding(operatorExpression: string): string {
  const operatorMap: Record<string, string> = {
    '>=': 'gte',
    '<=': 'lte',
    '>': 'gt',
    '<': 'lt',
    '=': 'eq',
  };
  return operatorMap[operatorExpression];
}

import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  error?: string;
};

export default function XlsxFileError({ error = 'Invalid file format.' }: Props) {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center" gap={3}>
      <Typography variant="h4" component="p">
        {error}
      </Typography>
      <Typography variant="body1" component="p">
        Please make sure to upload a valid file with liquid policies that match the XLSX
        template.
      </Typography>
    </Stack>
  );
}

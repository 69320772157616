import React from 'react';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import LiquidPolicyIntercomLink from 'client/app/components/Parameters/Policy/components/IntercomLink';
import RulesPreview from 'client/app/components/Parameters/Policy/components/RulesPreview';
import { LiquidPolicy } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import { MessagePreview } from 'common/ui/components/MessagePreview';

type Props = {
  policy: LiquidPolicy | undefined;
};

export default function DetailsView({ policy }: Props) {
  if (!policy) return null;

  return (
    <Background>
      <Stack flex={2} gap={8}>
        <Stack gap={4}>
          <Typography variant="h6" color="textSecondary">
            Description
          </Typography>
          <Typography variant="body1" color="textPrimary">
            <MessagePreview message={policy.description} messageType="markdown" />
          </Typography>
        </Stack>
        <HowRulesWork />
      </Stack>
      <RulesPanel flex={3}>
        <RulesPreview rules={policy.rules} />
      </RulesPanel>
    </Background>
  );
}

const HowRulesWork = React.memo(() => (
  <Stack gap={7}>
    <Stack gap={4}>
      <Stack direction="row" gap={2}>
        <InfoOutlined fontSize="small" style={{ color: Colors.TEXT_SECONDARY }} />
        <Typography variant="h6" color="textSecondary">
          How rules work
        </Typography>
      </Stack>
      <Typography variant="body2" color="textSecondary" component="p" lineHeight="1.5em">
        The liquid handling arm behaviour is optimised by applying the following rules in
        turn:
      </Typography>
    </Stack>

    <RulesHelpTimeline>
      <Typography variant="body2" color="textSecondary" component="p" lineHeight="1.5em">
        1. Synthace General Liquid Handling Rules
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p" lineHeight="1.5em">
        2. Liquid Policy: Main Rule
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p" lineHeight="1.5em">
        3. Liquid Policy: Additional Rules
      </Typography>
    </RulesHelpTimeline>

    <Typography variant="body2" color="textSecondary" component="p" lineHeight="1.5em">
      For more visit our help page <LiquidPolicyIntercomLink />.
    </Typography>
  </Stack>
));

//#region Styles

const Background = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  backgroundColor: Colors.GREY_5,
  padding: theme.spacing(8),
  gap: theme.spacing(4),
}));

const RulesPanel = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.paper,
  overflow: 'auto',
}));

const RulesHelpTimeline = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(8),
  paddingLeft: theme.spacing(7),

  position: 'relative',

  // Timeline Vertical line
  ['&:before']: {
    content: '""',
    position: 'absolute',
    left: 9,
    zIndex: 1,

    width: 1,
    height: 130,

    backgroundColor: Colors.GREY_30,
  },
  // Timeline Circle base
  ['& > p:before']: {
    content: '""',

    position: 'absolute',
    left: 1,
    zIndex: 2,

    width: 14,
    height: 14,

    borderRadius: '50%',
    border: `1px solid ${Colors.GREY_30}`,
  },
  ['& > p:nth-of-type(1):before']: {
    backgroundColor: Colors.GREY_5,
  },
  ['& > p:nth-of-type(2):before']: {
    backgroundColor: Colors.GREY_20,
  },
  ['& > p:nth-of-type(3):before']: {
    backgroundColor: Colors.GREY_40,
  },
}));

//#endregion

import React, { useCallback } from 'react';

import Typography from '@mui/material/Typography';

import { TopBar } from 'client/app/components/TopBar';
import { ContentType, WorkflowEditModeEnum, WorkflowSourceEnum } from 'client/app/gql';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { ScreenRegistry } from 'client/app/registry';
import { EditorType, WorkflowEditMode } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

type Props = {
  currentEditorType: WorkflowSourceEnum | EditorType;
  workflowEditorType: WorkflowSourceEnum | EditorType;
  authorName: string;
  editMode: WorkflowEditModeEnum | WorkflowEditMode;
  simulationId?: SimulationId;
  onCopyWorkflow: () => void;
  workflowContentSource: ContentType;
  /**
   * Id of the workflow this workflow was copied from.
   * This is needed for the button "View latest" which jumps to that parent workflow.
   * The id is null for workflow snapshots that were migrated from the legacy Job objects
   * (legacy storage, pre-May 2020). Those migrated workflows have no parent workflow.
   */
  parentWorkflowID: WorkflowId | null;
};

/**
 * This bar is shown when a workflow is not editable (e.g. it was created by another user).
 * It shows some information and allows users to copy the workflow with one click.
 * Used both in the WFE and the Cherry Picker.
 */
export const ReadonlyBar = React.memo((props: Props) => {
  const {
    currentEditorType,
    workflowEditorType,
    authorName,
    editMode,
    simulationId,
    onCopyWorkflow,
    workflowContentSource,
    parentWorkflowID,
  } = props;
  const handleCopyWorkflow = useCallback(() => {
    logEvent('copy-workflow-readonly', ScreenRegistry.WORKFLOW);
    onCopyWorkflow();
  }, [onCopyWorkflow]);
  if (
    (editMode === WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME ||
      editMode === WorkflowEditModeEnum.ENABLED_LATEST_EXAMPLE_GALLERY) &&
    currentEditorType === workflowEditorType
  ) {
    // Allow normal editing
    return null;
  }

  if (editMode !== WorkflowEditModeEnum.ENABLED_LATEST_OWNED_BY_ME) {
    return (
      <CreateCopyWorkflowBar
        authorName={
          editMode !== WorkflowEditModeEnum.DISABLED_SNAPSHOT_OWNED_BY_ME
            ? authorName
            : ''
        }
        handleCopyWorkflow={handleCopyWorkflow}
        simulationId={simulationId}
        workflowContentSource={workflowContentSource}
        currentEditorType={currentEditorType}
        parentWorkflowID={parentWorkflowID}
      />
    );
  }

  // Make sure we handled all possible edit modes
  throw new Error(
    'ReadonlyBar received an unexpected combination of Workflow edit mode, source, and current editor: ' +
      editMode +
      ', ' +
      workflowEditorType +
      ', ' +
      currentEditorType +
      '.',
  );
});

function CreateCopyWorkflowBar({
  authorName,
  simulationId,
  handleCopyWorkflow,
  workflowContentSource,
  currentEditorType,
  parentWorkflowID,
}: {
  authorName: string;
  simulationId?: SimulationId;
  handleCopyWorkflow: () => void;
  workflowContentSource: ContentType;
  currentEditorType: WorkflowSourceEnum | EditorType;
  parentWorkflowID: WorkflowId | null;
}) {
  const { route: workflowRoute } = getWorkflowPropsBySource(currentEditorType);

  const isExampleWorkflow = workflowContentSource === ContentType.EXAMPLE;
  const exampleCopy = `You are currently viewing an example workflow by ${authorName}. To edit or use this as a starting point for your own workflow, create a copy.`;
  const readOnlyCopy = `This is a read-only snapshot${
    authorName ? ` by ${authorName}` : ''
  }.`;

  return (
    <TopBar>
      <Typography variant="h6" color="textPrimary">
        {isExampleWorkflow ? exampleCopy : readOnlyCopy}
      </Typography>
      <Button
        data-heap-tracking="readonlybar-copy-workflow-button"
        onClick={handleCopyWorkflow}
        variant="primary"
        color="primary"
      >
        Make an editable copy
      </Button>
      {parentWorkflowID && !isExampleWorkflow && (
        <RouteButton
          data-heap-tracking="readonlybar-go-to-latest-button"
          route={workflowRoute}
          routeParam={{ workflowId: parentWorkflowID }}
          label="Go to latest"
          logEventCategory={ScreenRegistry.WORKFLOW}
          variant="contained"
          color="primary"
        />
      )}
      {simulationId && !isExampleWorkflow && (
        <RouteButton
          data-heap-tracking="readonlybar-go-to-simulation-button"
          route={simulationRoutes.openInSimulationDetails}
          routeParam={{ simulationId }}
          label="Go to simulation"
          logEventCategory={ScreenRegistry.WORKFLOW}
          variant="contained"
          color="primary"
        />
      )}
    </TopBar>
  );
}

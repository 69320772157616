import { GetOrganizationMemberRoles200ResponseOneOfInner as Role } from 'auth0';

export const CUSTOMER_PREFIX = 'Customer';
export const PREFIX_DELIMITER = ':';
export const NO_ROLE_COPY = 'No Role';
export const CUSTOMER_ROLE_DEFAULT = `${CUSTOMER_PREFIX}${PREFIX_DELIMITER} Member`;

export const ROLES = [
  CUSTOMER_ROLE_DEFAULT,
  `${CUSTOMER_PREFIX}${PREFIX_DELIMITER} Member`,
  `Synthace${PREFIX_DELIMITER} Customer Success`,
  `Synthace${PREFIX_DELIMITER} Standard User`,
  `Synthace${PREFIX_DELIMITER} Superuser`,
];

export function isCustomerRole(role: Role): boolean {
  const roleSplit = role.name?.split(PREFIX_DELIMITER);
  return !!roleSplit && roleSplit[0] === CUSTOMER_PREFIX && !!role.id;
}

export function removeRoleNamePrefix(name: string): string {
  return name.split(PREFIX_DELIMITER).slice(1).join(PREFIX_DELIMITER).trim();
}

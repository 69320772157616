import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import ParameterDetails from 'client/app/components/ElementDetails/ParameterDetails';
import { getElementDisplayDescription } from 'client/app/lib/workflow/elementConfigUtils';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import { Element } from 'common/types/bundle';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  element: Element;
  selectedParameterName?: string;
  selectedOutputName?: string;
};

export enum ElementDetailsTabs {
  DESCRIPTION,
  INPUTS,
  OUTPUTS,
}

const TABS_INFO: TabsInfo<ElementDetailsTabs> = [
  { value: ElementDetailsTabs.DESCRIPTION, label: 'Description' },
  { value: ElementDetailsTabs.INPUTS, label: 'Inputs' },
  { value: ElementDetailsTabs.OUTPUTS, label: 'Outputs' },
];

export default function ElementDetails(props: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(() =>
    props.selectedOutputName ? 2 : props.selectedParameterName ? 1 : 0,
  );
  const classes = useStyles();

  const { screenId } = useContext(ScreenContext);

  const handleTabChange = useCallback(
    (index: number) => {
      logEvent(
        'switch-tab-in-element-browser',
        screenId as string,
        TABS_INFO[index].label,
      );
      setActiveTabIndex(index);
    },
    [screenId],
  );

  const { element, selectedParameterName, selectedOutputName } = props;
  return (
    <div className={classes.root}>
      <Tabs
        activeTab={activeTabIndex}
        onChangeTab={handleTabChange}
        tabsInfo={TABS_INFO}
        minimumTabWidth="130px"
      />
      <TabPage
        tabIndex={activeTabIndex}
        element={element}
        selectedParameterName={selectedParameterName}
        selectedOutputName={selectedOutputName}
      />
    </div>
  );
}

type TabPageProps = Props & { tabIndex: number };

function TabPage(props: TabPageProps) {
  const classes = useStyles();
  const { tabIndex, element } = props;
  const selectedItemRef = useRef<HTMLDivElement | null>(null);

  const { elementConfigs } = useElementConfigs(element?.elementSetId || '');

  // If a user has accessed the ElementDetails dialog while trying to view
  // more info on a specific parameter, display the relevant info immediately.
  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView();
    }
  }, []);

  switch (tabIndex) {
    case 0: {
      let content;
      if (element.description) {
        content = (
          <MessagePreview
            message={getElementDisplayDescription(element, elementConfigs)}
            messageType="markdown"
          />
        );
      } else {
        content = <div className={classes.noDescription}>No information available.</div>;
      }

      return <div className={classes.tabPage}>{content}</div>;
    }
    case 1: {
      return (
        <div className={classes.tabPage}>
          {element.inputs.map(input => {
            const isParameterSelected =
              !!props.selectedParameterName && props.selectedParameterName === input.name;

            return (
              <div
                key={input.name}
                {...(isParameterSelected && { ref: selectedItemRef })}
              >
                <ParameterDetails
                  key={input.name}
                  parameter={input}
                  isHighlighted={isParameterSelected}
                  elementConfigs={elementConfigs}
                />
              </div>
            );
          })}
        </div>
      );
    }
    case 2:
      return (
        <div className={classes.tabPage}>
          {element.outputs.map(output => {
            const isOutputSelected =
              !!props.selectedOutputName && props.selectedOutputName === output.name;

            return (
              <div key={output.name} {...(isOutputSelected && { ref: selectedItemRef })}>
                <ParameterDetails
                  key={output.name}
                  parameter={output}
                  isHighlighted={isOutputSelected}
                  elementConfigs={elementConfigs}
                />
              </div>
            );
          })}
        </div>
      );
    default:
      throw new Error('invalid tabIndex');
  }
}

const useStyles = makeStylesHook(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(5, 6, 0, 6),
  },
  tabPage: {
    overflowY: 'auto',
    paddingTop: theme.spacing(4),
  },
  noDescription: {
    color: '#999',
    fontStyle: 'italic',
  },
}));

import React from 'react';

import cx from 'classnames';

import * as AnthaHardcoded from 'client/app/cdn/catalogue/components/antha-hardcoded/antha-hardcoded';
import { removeCommonPrefixes } from 'client/app/lib/antha-lang/identifiers';
import {
  getParameterDisplayDescription,
  getParameterDisplayName,
} from 'client/app/lib/workflow/elementConfigUtils';
import { renderParameterDescription } from 'client/app/lib/workflow/format';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Parameter } from 'common/types/bundle';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  parameter: Parameter;
  /** Used if navigating from a ParameterHeader, to highlight what the user clicked on  */
  isHighlighted?: boolean;
  elementConfigs?: Record<string, ElementConfigurationSpec | null>;
};

export default function ParameterDetails(props: Props) {
  const { parameter } = props;
  const classes = useStyles();

  const typeColor = ColorByType.get(parameter.type) || DEFAULT_TYPE_COLOR;
  const typeLabel = removeCommonPrefixes(parameter.type);
  // We apply a transparent white above the original color to lighten it and improve contrast with the text
  const typeBG = `${typeColor} linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))`;

  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );

  let useParameterConfig = false;
  if (parameter.configuration) {
    if (parameter.configuration.isVisible === false) {
      return null;
    }
    useParameterConfig = true;
  }

  const displayName = getParameterDisplayName(parameter, isElementConfigDebugModeEnabled);

  const description = getParameterDisplayDescription(parameter, props.elementConfigs);

  return (
    <div
      className={cx({
        [classes.root]: true,
        [classes.highlight]: props.isHighlighted,
      })}
    >
      <div className={classes.square} style={{ backgroundColor: typeColor }} />
      <div className={classes.content}>
        <span className={classes.name}>{displayName}</span>
        <span
          className={classes.type}
          style={{
            background: typeBG,
          }}
          title={typeLabel}
        >
          {typeLabel}
        </span>
        <br />
        <span className={classes.description}>
          {useParameterConfig ? (
            <MessagePreview message={description} messageType="markdown" />
          ) : (
            renderParameterDescription(description)
          )}
        </span>
      </div>
    </div>
  );
}

const DEFAULT_TYPE_COLOR = '#ccc';
const ColorByType = new Map(
  AnthaHardcoded.TypeColors().map(({ color, type }) => [type, color]),
);

const useStyles = makeStylesHook({
  root: {
    display: 'flex',
    padding: '10px',
    marginBottom: '10px',
  },
  content: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: 1.5,
  },
  name: {
    fontWeight: 700,
    color: '#555',
    marginRight: '0.5em',
  },
  type: {
    fontWeight: 400,
    fontStyle: 'italic',
    color: 'black',
    padding: '0px 6px',
    fontSize: '0.9em',
  },
  square: {
    width: '16px',
    height: '16px',
    marginRight: '10px',
    marginTop: '2px',
    flexShrink: 0,
  },
  description: {
    '& p:first-child': {
      marginTop: 0,
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },
  highlight: {
    background: Colors.TEXT_HIGHLIGHT,
  },
});

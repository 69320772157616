import React, { useState } from 'react';

import AttachFile from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { useCreatorFilter } from 'client/app/apps/policy-library/filters';
import useLiquidPolicies from 'client/app/apps/policy-library/useLiquidPolicies.hook';
import LiquidPolicyCard from 'client/app/components/cards/LiquidPolicyCard/LiquidPolicyCard';
import { LiquidPolicy, LiquidPolicySourceEnum } from 'client/app/gql';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import FilterChipWithCheckbox from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import SearchField from 'common/ui/components/SearchField';
import useDebounce from 'common/ui/hooks/useDebounce';

type Props = {
  /** Name of the liquid policy set in element parameters */
  selectedPolicyName?: string | undefined;
  /**
   * Name of the uploaded XLSX file with custom liquid policies.
   *
   * FIXME: the XLSX view is for backward compatibility with XLSX file policies.
   * Should be removed when XLSX policies are deprecated.   */
  xlsxFileName?: string;
  /** Should toggle liquid policy highlight */
  onCardClick?: (policy: LiquidPolicy) => void;
  /** Should select the liquid policy */
  onCardDoubleClick?: (policy: LiquidPolicy) => void;
  /** Navigates to liquid policy details view */
  onViewDetails?: (policy: LiquidPolicy) => void;
  /**
   * Navigates to a view with XLSX liquid types preview
   *
   * FIXME: the XLSX view is for backward compatibility with XLSX file policies.
   * Should be removed when XLSX policies are deprecated.
   */
  onViewXLSX?: () => void;
  /** Deletes liquid policy by setting `is_deleted` to true */
  onDelete?: (policy: LiquidPolicy) => Promise<void>;
  /** Selects liquid policy for editing */
  onEdit?: (policy: LiquidPolicy) => void;
  /** Populate dialog in 'create' mode with liquid policy data */
  onCopy?: (policy: LiquidPolicy) => void;
};

export default function PolicyLibraryApp({
  selectedPolicyName,
  xlsxFileName,
  onCardClick,
  onCardDoubleClick,
  onViewDetails,
  onViewXLSX,
  onDelete,
  onEdit,
  onCopy,
}: Props) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const handleQueryChange = useDebounce(setSearchQuery, 200);

  const creatorFilter = useCreatorFilter();

  const liquidPolicies = useLiquidPolicies(searchQuery, creatorFilter);

  const isSelected = (policy: LiquidPolicy) =>
    selectedPolicyName?.toLowerCase() === policy.name.toLowerCase();

  return (
    <Stack flexGrow={1} pt={5}>
      <ContainerWithIntersectionBar
        headerLeftContent={
          <Stack direction="row" py={5}>
            <FilterChipWithCheckbox<LiquidPolicySourceEnum>
              heading="Filter by Creator"
              defaultChipLabel="Policy Creator"
              filterValue={creatorFilter.value}
              onFilter={creatorFilter.onChange}
            />
          </Stack>
        }
        headerRightContent={
          <Stack py={5}>
            <SearchField
              onQueryChange={handleQueryChange}
              placeholder="Search"
              addMargin
            />
          </Stack>
        }
        content={
          liquidPolicies.loading ? (
            <LinearProgress />
          ) : (
            <Stack gap={6} px={8} py={6}>
              {liquidPolicies.total === 0 && (
                <NoEntitiesMessage
                  entityName="liquid policies"
                  messageType={MessageType.NO_FILTER_RESULTS}
                  searchQuery={searchQuery}
                />
              )}
              <Stack direction="row" gap={6} flexWrap="wrap">
                {liquidPolicies.total > 0 && onViewXLSX && (
                  <CardPlaceholder>
                    <Typography variant="h3" color="inherit">
                      Custom policies found attached to the workflow
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <AttachFile fontSize="small" />
                      <Typography variant="caption" fontWeight={700}>
                        {xlsxFileName}
                      </Typography>
                    </Stack>
                    <Button variant="outlined" color="secondary" onClick={onViewXLSX}>
                      Choose from uploaded file
                    </Button>
                  </CardPlaceholder>
                )}
                {liquidPolicies.custom.map(policy => (
                  <LiquidPolicyCard
                    key={policy.id}
                    policy={policy}
                    selected={isSelected(policy)}
                    onClick={onCardClick}
                    onDoubleClick={onCardDoubleClick}
                    onViewDetails={onViewDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onCopy={onCopy}
                  />
                ))}
              </Stack>
              <Stack direction="row" gap={6} flexWrap="wrap">
                {liquidPolicies.system.map(policy => (
                  <LiquidPolicyCard
                    key={policy.id}
                    policy={policy}
                    selected={isSelected(policy)}
                    onClick={onCardClick}
                    onDoubleClick={onCardDoubleClick}
                    onViewDetails={onViewDetails}
                    onCopy={onCopy}
                  />
                ))}
              </Stack>
            </Stack>
          )
        }
      />
    </Stack>
  );
}

const CardPlaceholder = styled(Stack)(({ theme }) => ({
  width: 325,
  height: 244,

  gap: theme.spacing(5),

  paddingTop: 60,
  paddingRight: 70,
}));

import React, { useCallback, useContext, useMemo, useState } from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import produce from 'immer';
import memoiseBind from 'memoize-bind';

import { IntercomTourIDs } from 'common/lib/intercom';
import { Markdown } from 'common/lib/markdown';
import { SpreadsheetStateContext } from 'common/rules/SpreadsheetStateContext';
import { SpreadsheetConfiguration } from 'common/types/spreadsheet';
import { DataTable, Spreadsheet } from 'common/types/spreadsheetEditor';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import {
  getCustomSpreadsheet,
  getDataFromSpreadsheet,
  getDefaultSpreadsheet,
  getRows,
} from 'common/ui/components/Dialog/spreadsheetHelpers';
import IconWithPopover from 'common/ui/components/IconWithPopover';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import Table, { CellEditorComponentType } from 'common/ui/components/Table';
import TableTopBar, {
  DownloadFileProps,
  EditableSheetProps,
  MultiSheetProps,
  TABLE_TOPBAR_HEIGHT,
} from 'common/ui/components/TableTopBar';
import { TabsInfo } from 'common/ui/components/Tabs';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

/** How many rows to append to the table when users scroll to the bottom */
const ROWS_TO_APPEND = 5;

type Props = {
  title?: string;
  info?: Markdown;
  workflowName?: string;
  data?: DataTable | DataTable[];
  cellEditorComponent?: CellEditorComponentType;
  configuration: SpreadsheetConfiguration;
  isReadonly: boolean;
  shouldReturnArray: boolean;
  onChange?: (data: DataTable | DataTable[] | undefined) => void;
} & DialogProps<DataTable | DataTable[] | null>;

export default function SpreadsheetEditorDialog({
  title,
  info,
  cellEditorComponent,
  data,
  isOpen,
  isReadonly,
  workflowName,
  configuration,
  onClose,
  shouldReturnArray,
  onChange,
}: Props) {
  const classes = useStyles();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [stagedConfiguration, setStagedConfiguration] = useState(configuration);
  // The parameter accepts DataTable values. However, we keep a Spreadsheet
  // in the UI state, as we need the extra information.
  const [stagedSpreadsheet, setStagedSpreadsheet] = useState<Spreadsheet | null>(() => {
    if (!data) {
      return getDefaultSpreadsheet(stagedConfiguration);
    } else {
      const dataTables = Array.isArray(data) ? data : [data];
      const sheetNames = stagedConfiguration.sheets.map(config => config.name);
      const spreadsheet = {
        sheets: dataTables.map((table, i) => ({
          name: sheetNames ? sheetNames[i] : `Sheet${i}`,
          table,
        })),
      };
      return getCustomSpreadsheet(stagedConfiguration, spreadsheet);
    }
  });

  const state = useContext(SpreadsheetStateContext);

  const handleSave = useCallback(() => {
    if (!stagedSpreadsheet) {
      onClose(null);
      return;
    }
    const data = getDataFromSpreadsheet(stagedSpreadsheet, shouldReturnArray);
    onClose(data);
  }, [onClose, shouldReturnArray, stagedSpreadsheet]);

  const handleChangeDataTable = useCallback(
    (sheetIndex: number, newData: DataTable) => {
      setStagedSpreadsheet(oldSpreadsheet => {
        if (!oldSpreadsheet) {
          return null;
        }

        const newSpreadsheet = produce(oldSpreadsheet, draft => {
          draft.sheets[sheetIndex].table = newData;
        });

        return newSpreadsheet;
      });
      onChange?.(newData);
    },
    [onChange],
  );

  const handleAddMoreRows = useCallback(
    (sheetIndex: number) => {
      setStagedSpreadsheet(oldSpreadsheet => {
        if (!oldSpreadsheet) {
          return null;
        }

        return produce(oldSpreadsheet, draft => {
          draft.sheets[sheetIndex].table.data.push(
            ...getRows(ROWS_TO_APPEND, stagedConfiguration.sheets[sheetIndex].columns),
          );
        });
      });
      return ROWS_TO_APPEND;
    },
    [stagedConfiguration.sheets],
  );

  const sheetsInfo: TabsInfo<number> = useMemo(
    () =>
      stagedSpreadsheet?.sheets.map((sheet, index) => ({
        label: sheet.name,
        value: index,
      })) ?? [],
    [stagedSpreadsheet?.sheets],
  );

  const multiSheetProps: MultiSheetProps = useMemo(
    () => ({
      activeTabIndex,
      onChangeTab: setActiveTabIndex,
      setStagedSpreadsheet,
      sheetsInfo,
    }),
    [activeTabIndex, sheetsInfo],
  );

  const editableSheetProps: EditableSheetProps = useMemo(
    () => ({
      setSpreadsheet: setStagedSpreadsheet,
      configuration: stagedConfiguration,
      setConfiguration: setStagedConfiguration,
      state,
    }),
    [stagedConfiguration, state],
  );

  const downloadFileProps: DownloadFileProps = useMemo(
    () => ({
      dataToDownload: stagedSpreadsheet,
      filename: workflowName && title ? `${workflowName} - ${title}` : 'Spreadsheet',
    }),
    [title, stagedSpreadsheet, workflowName],
  );

  const canAddColumns =
    stagedConfiguration.sheets[activeTabIndex].canAddColumns && !isReadonly;

  return (
    <Dialog
      open={isOpen}
      onClose={handleSave}
      fullScreen
      disableEscapeKeyDown
      PaperProps={{ sx: { overflow: 'hidden' } }}
    >
      {title && (
        <DialogTitle component="div" className={classes.dialogHeader}>
          {title}

          {info && (
            <Box marginLeft={3} alignItems="center">
              <IconWithPopover
                icon={<HelpIcon />}
                popoverContent={<MessagePreview message={info} messageType="markdown" />}
              />
            </Box>
          )}
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        <div className={classes.topBarContainer}>
          <TableTopBar
            downloadFileProps={downloadFileProps}
            multiSheetProps={multiSheetProps}
            editableSheetProps={!isReadonly ? editableSheetProps : undefined}
            canAddColumns={canAddColumns}
          />
        </div>

        {stagedSpreadsheet?.sheets.map((sheet, index) => (
          <div
            key={`wrapper-${index}`}
            hidden={activeTabIndex !== index}
            className={classes.table}
          >
            {isReadonly ? (
              <Table
                key={`sheet-${index}`}
                title={title}
                dataTable={sheet.table}
                configuration={stagedConfiguration.sheets[index]}
                cellEditorComponent={cellEditorComponent}
                isReadonly={isReadonly}
              />
            ) : (
              <Table
                key={`sheet-${index}`}
                state={state.sheets[sheet.name]}
                dataTable={sheet.table}
                configuration={stagedConfiguration.sheets[index]}
                cellEditorComponent={cellEditorComponent}
                title={title}
                onDataTableChange={memoiseBind(handleChangeDataTable, null, index)}
                onAddMoreRows={memoiseBind(handleAddMoreRows, null, index)}
              />
            )}
          </div>
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          data-intercom-target={`${IntercomTourIDs.ELISA_SPREADSHEET}-spreadsheet-done`}
          onClick={handleSave}
          variant="primary"
          color="primary"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogHeader: {
    borderBottom: `${Colors.GREY_30} 2px solid`,
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    // Prevent dialog content to scroll and have the table itself
    // scroll. This way the table sticky headers will work.
    overflowY: 'hidden',
  },
  table: {
    overflowY: 'auto',
    height: `calc(100% - ${TABLE_TOPBAR_HEIGHT})`,
  },
  dialogActions: {
    borderTop: `${Colors.GREY_30} 2px solid`,
    marginRight: theme.spacing(4),
  },
  topBarContainer: {
    marginBottom: theme.spacing(4),
  },
}));

import { useLayoutEffect } from 'react';

export default function useShowListOnOpen({
  isOpen,
  showList,
}: {
  isOpen: boolean;
  showList: () => void;
}) {
  useLayoutEffect(() => {
    if (isOpen) {
      showList();
    }
  }, [isOpen, showList]);
}

import React from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LiquidPolicyIntercomLink from 'client/app/components/Parameters/Policy/components/IntercomLink';
import { formatDateTime } from 'common/lib/format';
import Popover from 'common/ui/components/Popover';

export const ListViewTitle = () => (
  <Stack flexGrow={1} direction="row" gap={3} alignItems="center">
    <Typography variant="h2">Liquid Policies</Typography>
    <Popover title={<DialogHelp />} placement="bottom-start">
      <InfoIcon fontSize="small" />
    </Popover>
  </Stack>
);

type DetailsViewTitleProps = {
  name: string | undefined;
  author: string | undefined;
  createDate: string | undefined;
};

export const DetailsViewTitle = ({
  name = 'N/A',
  author = 'Synthace',
  createDate = 'N/A',
}: DetailsViewTitleProps) => (
  <Stack flexGrow={1} gap={1}>
    <Typography variant="h3" fontWeight={500}>
      {name}
    </Typography>
    <Stack direction="row" gap={5}>
      <Stack direction="row" gap={3}>
        <Typography variant="body2">Created by:</Typography>
        <Typography variant="body2" fontWeight={700}>
          {author}
        </Typography>
      </Stack>
      <Divider orientation="vertical" flexItem />
      <Stack direction="row" gap={3}>
        <Typography variant="body2">Last updated:</Typography>
        <Typography variant="body2" fontWeight={700}>
          {formatDateTime(new Date(createDate))}
        </Typography>
      </Stack>
    </Stack>
  </Stack>
);

export const XlsxViewTitle = () => (
  <Stack flexGrow={1} direction="row" gap={3} alignItems="center">
    <Typography variant="h2">Choose liquid type from custom file</Typography>
    <Popover title={<DialogHelp />} placement="bottom-start">
      <InfoIcon fontSize="small" />
    </Popover>
  </Stack>
);

const DialogHelp = () => (
  <Stack gap={2} p={2}>
    <Typography variant="caption" color="textPrimary" component="p">
      Synthace also applies general liquid handling rules to all liquid types under
      certain conditions.
    </Typography>
    <Typography variant="caption" color="textPrimary" component="p">
      Learn about them <LiquidPolicyIntercomLink />.
    </Typography>
  </Stack>
);

import React, { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { createStyles } from '@mui/styles';

import { NO_ROLE_COPY } from 'common/lib/roles';
import Dropdown from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { theme } from 'common/ui/theme';

export type Role = {
  __typename?: string;
  id: string;
  name: string;
  description?: string;
};

type UserRoleProps = {
  currentRole: Role | null;
  availableRoles: readonly Role[] | undefined;
  onChange: (roleId: string | null) => void;
  label?: string;
  variant?: 'default' | 'hidden' | 'nullSelectable';
};

const dropdownValueAsTypography = (value: string) => (
  <Typography variant="body2">{value}</Typography>
);

export const UserRole = React.memo(
  ({
    currentRole,
    availableRoles,
    onChange,
    label,
    variant = 'default',
  }: UserRoleProps) => {
    const classes = useStyles();

    const displayRoleEditor = !!availableRoles && availableRoles.length > 0;
    const currentRoleText = currentRole ? currentRole.name : NO_ROLE_COPY;

    const selectCallback = useCallback(
      (value: string | null | undefined) => value !== undefined && onChange(value),
      [onChange],
    );

    if (variant === 'hidden') {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    if (!displayRoleEditor) {
      return (
        <Typography variant="body2" className={classes.marginRight}>
          {currentRoleText}
        </Typography>
      );
    }

    const roles: { label: string; value: string | null }[] = availableRoles.map(role => ({
      label: role.name,
      value: role.id,
    }));
    if (currentRole && variant === 'nullSelectable') {
      roles.push({ label: NO_ROLE_COPY, value: null });
    }

    return (
      <div>
        <Dropdown
          valueLabel={currentRoleText}
          placeholder={currentRole ? undefined : NO_ROLE_COPY}
          options={roles}
          onChange={selectCallback}
          renderValue={dropdownValueAsTypography}
          className={classes.selectClass}
          {...(label ? { label } : {})}
        />
      </div>
    );
  },
);

const useStyles = makeStylesHook(
  createStyles({
    marginRight: {
      marginRight: theme.spacing(4),
    },
    selectClass: {
      minWidth: '100px',
    },
  }),
);

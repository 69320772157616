import { styled } from '@mui/material/styles';

import Colors from 'common/ui/Colors';

export const TopBar = styled('div')(({ theme: { spacing } }) => ({
  alignItems: 'center',
  padding: spacing(3),
  background: Colors.INFO_LIGHT,
  borderBottom: `1px ${Colors.GREY_30} solid`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  gap: spacing(5),
}));

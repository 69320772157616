import React, { useMemo, useState } from 'react';

import ErrorOutlined from '@mui/icons-material/ErrorOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { ActivePlateSelector } from 'client/app/components/Parameters/PlateLayout/LayoutPlate';
import MixturesPlate from 'client/app/components/Parameters/PlateLayout/MixturesPlate';
import MixturesTable from 'client/app/components/Parameters/PlateLayout/MixturesTable';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { useOutputMixturesByPlate } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { useElementErrorData } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { stringToMarkdown } from 'common/lib/markdown';
import Button from 'common/ui/components/Button';
import Feedback from 'common/ui/components/Feedback';
import LinearProgress from 'common/ui/components/LinearProgress';
import { MessagePreview, sxSmallerHeadings } from 'common/ui/components/MessagePreview';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';

type Props = { className?: string };

export default function MixturesPreview({ className }: Props) {
  const [activeView, setActiveView] = useState<'plate' | 'table'>('plate');

  const dispatch = useWorkflowBuilderDispatch();
  const { selectedPlateName, liquidParameters } = usePlateLayoutEditorContext();
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);
  const [loading, plates, uniqueMixtures] = useOutputMixturesByPlate(
    liquidColors,
    liquidParameters,
  );

  const isEnabledParameterValidation = useFeatureToggle('PARAMETER_VALIDATION');

  const elementErrors = useElementErrorData();
  const currentElement = useElementInstance();

  const visiblePlate: PlateState | undefined =
    plates.get(selectedPlateName ?? '') ?? plates.values().next().value;

  const showErrors = useMemo(() => {
    return !loading && !visiblePlate && elementErrors.length > 0;
  }, [elementErrors.length, loading, visiblePlate]);

  const focusOnElement = (elementId: string) => {
    dispatch({ type: 'centerToElement', payload: { elementId, selectElement: true } });
  };

  if (showErrors) {
    return (
      <ErrorsWrapper className={className}>
        <Feedback
          variant="error"
          content={
            <Typography>
              The workflow has errors, which are preventing the generation of the
              mixtures. Please fix them to enable the preview.
            </Typography>
          }
        />
        {isEnabledParameterValidation && (
          <ErrorList>
            {elementErrors.map((error, index) => (
              <ErrorItem key={`${error.elementId}-${index}`}>
                {error.severity === 'error' ? <ErrorIcon /> : <WarningIcon />}
                <ErrorTitle variant="h3">
                  Element &quot;{error.errorTitle}&quot;
                </ErrorTitle>
                <ErrorMessage>
                  {error.elementId !== currentElement?.Id && (
                    <Button
                      endIcon={<KeyboardArrowRight />}
                      variant="secondary"
                      onClick={() => focusOnElement(error.elementId)}
                    >
                      View Element
                    </Button>
                  )}
                  <Typography variant="subtitle2">Summary</Typography>
                  <ErrorMessageSection>
                    <MessagePreview
                      message={stringToMarkdown(error.message ?? '')}
                      messageType={error.messageType}
                      sx={sxSmallerHeadings}
                    />
                  </ErrorMessageSection>
                  {!!error.details && (
                    <>
                      <Typography variant="subtitle2">Details</Typography>
                      <ErrorMessageSection>
                        <MessagePreview
                          message={stringToMarkdown(error.details ?? '')}
                          messageType={error.messageType}
                          sx={sxSmallerHeadings}
                        />
                      </ErrorMessageSection>
                    </>
                  )}
                </ErrorMessage>
              </ErrorItem>
            ))}
          </ErrorList>
        )}
      </ErrorsWrapper>
    );
  }

  return (
    <Wrapper className={className}>
      <Header>
        <Feedback
          variant="info"
          content={
            <Typography>
              This is a preview of the mixtures generated by the configured layout. They
              cannot be edited directly here.
            </Typography>
          }
        />
        <ViewToggle
          value={activeView}
          onChange={(_, value) => setActiveView(value)}
          exclusive
          disabled={loading}
        >
          <ToggleButton value="plate">Plate</ToggleButton>
          <ToggleButton value="table">Table</ToggleButton>
        </ViewToggle>
      </Header>
      {loading && <StyledLinearProgress />}
      {!loading && visiblePlate && (
        <>
          {activeView === 'plate' ? (
            <MixturesPlate
              plate={visiblePlate}
              uniqueMixtures={uniqueMixtures}
              liquidColors={liquidColors}
            />
          ) : (
            <MixturesTable />
          )}
        </>
      )}
      <ActivePlateSelector disabled={loading} />
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'grid',
  gridTemplate: `
    'header header' auto
    'plate mixtures' minmax(0, 1fr)
    'plateSelect mixtures' auto / minmax(250px, 1fr) 250px`,
  placeItems: 'stretch',
  gap: theme.spacing(6),
}));

const Header = styled('div')(({ theme }) => ({
  gridArea: 'header',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const ViewToggle = styled(Toggle)({
  width: '250px',
});

const StyledLinearProgress = styled(LinearProgress)({
  gridColumnStart: 'plate',
  gridColumnEnd: 'mixtures',
});

const ErrorsWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  overflowY: 'auto',
}));

const ErrorList = styled('ul')({
  margin: 0,
  padding: 0,
});

const ErrorItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(6, 3, 0, 4),
  display: 'grid',
  gridTemplate: `
  'icon element' auto
  '. details' auto / auto 1fr
`,
  gap: theme.spacing(4, 5),
  alignItems: 'center',
}));

const ErrorTitle = styled(Typography)({
  gridArea: 'element',
});

const ErrorIcon = styled(ErrorOutlined)(({ theme }) => ({
  gridArea: 'icon',
  color: theme.palette.error.main,
}));

const WarningIcon = styled(NotificationsOutlined)(({ theme }) => ({
  gridArea: 'icon',
  color: theme.palette.warning.dark,
}));

const ErrorMessage = styled('div')(({ theme }) => ({
  gridArea: 'details',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: theme.spacing(5),
}));

const ErrorMessageSection = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(6),
}));
